import React from 'react'
import { graphql } from 'gatsby'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import BlockContent from '../components/block-content'
// import Img from 'gatsby-image'
import Layout from '../containers/layout'
import Images from '../components/images'
import BackgroundImage from 'gatsby-background-image'

import LogoBrown from '../svg/logo_brown.svg'
import Bald from '../svg/bald.svg'
import Face from '../svg/face.svg'
import Mask from '../svg/mask.svg'
import Beard from '../svg/beard.svg'
import Hairpieces from '../svg/hairpieces.svg'
import Unterbauten from '../svg/unterbauten.svg'

export const query = graphql`
  query HerstellungPageQuery {

    background: sanityBackgrounds {
      herstellungPage {
        _key
        _type
        alt
        asset {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }

    page: sanityHerstellung(_id: { regex: "/(drafts.|)herstellung/" }) {
      id
      titlewigs
      titlebaldy
      titlebeards
      titlefaces
      titlehairpieces
      titlemasks
      titleunterbauten
      _rawBodybaldy
      _rawBodybeards
      _rawBodyfaces
      _rawBodyhairpieces
      _rawBodymasks
      _rawBodyunterbauten
      _rawBodywigs
      slideshowbaldy {
        slides {
          asset {
            id
            url
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          _id
          }
          _key
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          alt
          caption
        }
      }
      slideshowbeards {
        slides {
          asset {
            id
            url
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          _id
          }
          _key
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          alt
          caption
        }
      }
      slideshowfaces {
        slides {
          asset {
            id
            url
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          _id
          }
          _key
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          alt
          caption
        }
      }
      slideshowmasks {
        slides {
          asset {
            id
            url
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          _id
          }
          _key
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          alt
          caption
        }
      }
      slideshowpieces {
        slides {
          asset {
            id
            url
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          _id
          }
          _key
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          alt
          caption
        }
      }
      slideshowunterbauten {
        slides {
          asset {
            id
            url
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          _id
          }
          _key
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          alt
          caption
        }
      }
      slideshowwigs {
        slides {
          asset {
            id
            url
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          _id
          }
          _key
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          alt
          caption
        }
      }
    }
  }
`

const HerstellungPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const background = (data || {}).background
  const site = (data || {}).site

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const page = data && data.page

  if (!page) {
    throw new Error(
      'Missing "Herstellung" page data. Open the studio at http://localhost:3333 and add "Herstellung" page data and restart the development server.'
    )
  }

  return (
    <Layout pageLayout='normal-footer'>

      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <article className='sections'>
        <section className='page-section white herstellung'>

          <div className='mainImage hero--herstellung hero'>
            <BackgroundImage
              Tag='section'
              style={{
                backgroundSize: 'cover',
                backgroundPosition: 'top center',
                backgroundRepeat: 'none',
                display: 'block',
                position: 'fixed',
                height: '100%',
                width: '100%',
                willChange: 'transform'
              }}
              fluid={background.herstellungPage.asset.fluid}
              backgroundColor={`#040e18`}
              fadeIn={false}
            />
            <div className='content-wrapper herstellung-page'>
              <div className='content'>

                <div className='icon-menu-wrapper'>
                  <nav className='icon-nav'>
                    <ul>
                      <li className='wigs-logo-hack'>
                        <AnchorLink href='#wigs'>
                          <LogoBrown />
                        </AnchorLink>
                        <span className='icon-title'>Perücken</span>
                      </li>
                      <li className='hairpieces-logo-hack'>
                        <AnchorLink href='#hairpieces'><Hairpieces /></AnchorLink>
                        <span className='icon-title'>Haarteile & Tressen</span>
                      </li>
                      <li>
                        <AnchorLink href='#beard'><Beard /></AnchorLink>
                        <span className='icon-title'>Bärte</span>
                      </li>
                      <li className='face-logo-hack'>
                        <AnchorLink href='#face'><Face /></AnchorLink>
                        <span className='icon-title'>Gesichtsteile</span>
                      </li>
                      <li>
                        <AnchorLink href='#baldy'><Bald /></AnchorLink>
                        <span className='icon-title'>Glatzen</span>
                      </li>
                      <li className='unterbauten-logo-hack'>
                        <AnchorLink href='#gestelle'><Unterbauten /></AnchorLink>
                        <span className='icon-title'>Unterbauten & Gestelle</span>
                      </li>
                      <li className='mask-logo-hack'>
                        <AnchorLink href='#mask'><Mask /></AnchorLink>
                        <span className='icon-title'>Masken</span>
                      </li>
                    </ul>
                  </nav>
                </div>

                <div className='herstellung-sections-wrapper'>

                  <div id='wigs' className='section-wrapper'>
                    <h1>{page.titlewigs}</h1>
                    {page._rawBodywigs && <BlockContent blocks={page._rawBodywigs || []} />}
                    {page.slideshowwigs && <Images data={page.slideshowwigs || []} />}
                  </div>

                  <div id='hairpieces' className='section-wrapper'>
                    <h1>{page.titlehairpieces}</h1>
                    {page._rawBodyhairpieces && <BlockContent blocks={page._rawBodyhairpieces || []} />}
                    {page.slideshowpieces && <Images data={page.slideshowpieces || []} />}
                  </div>

                  <div id='beard' className='section-wrapper'>
                    <h1>{page.titlebeards}</h1>
                    {page._rawBodybeards && <BlockContent blocks={page._rawBodybeards || []} />}
                    {page.slideshowbeards && <Images data={page.slideshowbeards || []} />}
                  </div>

                  <div id='face' className='section-wrapper'>
                    <h1>{page.titlefaces}</h1>
                    {page._rawBodyfaces && <BlockContent blocks={page._rawBodyfaces || []} />}
                    {page.slideshowfaces && <Images data={page.slideshowfaces || []} />}
                  </div>

                  <div id='baldy' className='section-wrapper'>
                    <h1>{page.titlebaldy}</h1>
                    {page._rawBodybaldy && <BlockContent blocks={page._rawBodybaldy || []} />}
                    {page.slideshowbaldy && <Images data={page.slideshowbaldy || []} />}
                  </div>

                  <div id='gestelle' className='section-wrapper'>
                    <h1>{page.titleunterbauten}</h1>
                    {page._rawBodyunterbauten && <BlockContent blocks={page._rawBodyunterbauten || []} />}
                    {page.slideshowunterbauten && <Images data={page.slideshowunterbauten || []} />}
                  </div>

                  <div id='mask' className='section-wrapper'>
                    <h1>{page.titlemasks}</h1>
                    {page._rawBodymasks && <BlockContent blocks={page._rawBodymasks || []} />}
                    {page.slideshowmasks && <Images data={page.slideshowmasks || []} />}
                  </div>

                </div>

              </div>
            </div>

          </div>

        </section>
      </article>

    </Layout>
  )
}

export default HerstellungPage
